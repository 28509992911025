import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, arrayDiffByKey } from "../lib/helpers";
import ProjectsGrid from "../components/work-projects-grid";
import CategoryList from "../components/work-category-list";

export const query = graphql`
  query CategoryTemplateQuery($id: String!) {
    categories: allSanityCategory(
      sort: { order: ASC, fields: order }
      filter: { featured: { eq: true } }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
    category: sanityCategory(id: { eq: $id }) {
      title
      projectLink {
        ...SanityProjectLinkFragment
      }
    }
    projects: allSanityProject(
      sort: { order: ASC, fields: order }
      filter: {
        categories: { elemMatch: { id: { eq: $id } } }
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
      }
    ) {
      edges {
        node {
          ...SanityProjectFragment
        }
      }
    }
  }
`;

const CategoryTemplateQuery = (props) => {
  const { data, errors } = props;
  const { title, description } = data.category || {};
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const categoryNodes =
    data && data.categories && mapEdgesToNodes(data.categories).filter(filterOutDocsWithoutSlugs);
  const allProjectNodes = data && data.category.projectLink;
  const projectNodes =
    data && data.projects && mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs);
  const filterProjectNodes = arrayDiffByKey("id", projectNodes, allProjectNodes);
  const nodes = allProjectNodes.concat(filterProjectNodes);

  return (
    <Layout>
      <SEO title={data.category.title} description={data.category.description} />
      <Container>
        <h1 hidden>{title}</h1>
        {categoryNodes && categoryNodes.length > 0 && (
          <CategoryList activeCat={data.category} nodes={categoryNodes} />
        )}
        {projectNodes && projectNodes.length > 0 && <ProjectsGrid nodes={nodes} />}
      </Container>
    </Layout>
  );
};

export default CategoryTemplateQuery;
